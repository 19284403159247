.check-remarks-container {
  border: 1px solid #d3d3d3;
  margin-top: 10px;
  border-radius: 3px;
  padding: 15px 10px 10px 10px;
}

.check-remarks-container-title {
  color: #646464;
  font-style: normal;
  font-size: 15px;
  border-bottom: 1px solid #d3d3d3;
  padding: 0px 2px 10px 5px;
  margin-top: 3px;
  display: inline-block;
  line-height: 20px;
}

.check-remarks-btn-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.check-remarks-btn-section button {
  min-width: 50px;
  border: 1px solid #d3d3d3;
  margin: 2px;
  border-radius: 3px;
  cursor: pointer;
  font-family: "BarlowCondensed";
  font-weight: 500;
  font-size: 14px;
}

.check-remarks-btn-section button.active {
  background: #f17940;
  border: 1px solid #f17940;
  color: #ffffff;
  font-weight: 700;
}

.check-remarks-warn-label {
  display: inline-block;
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  padding: 8px;
  border-radius: 3px;
  margin: 2px;
}

.check-remarks-second-section {
  margin-left: 20px;
}
