.book-stock-component-body {
  height: 100vh;
  background-color: #f6f6f6;
}

.book-stock-subheader {
  position: relative;
  margin: 0 auto;
  z-index: 4;
  padding: 55px 0;
  background-color: #eeeeee;
  background-position: center top;
  background-attachment: scroll;
}

.book-stock-subheader h1 {
  color: #444444;
  text-align: center;
  font-weight: 800;
  font-size: 43px;
  padding-bottom: 20px;
  width: 80%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.book-stock-tab-container {
  max-width: 900px;
  margin: auto;
  width: 90%;
}

.storage-information {
  float: left;
  width: 33%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.storage-information textarea {
  display: block;
  min-height: 20px;
  max-height: 230px;
  height: 100px;
  width: 90%;
  max-width: 90%;
  min-width: 90%;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(246, 246, 246, 1);
  border: 1px solid lightGrey;
  outline: none;
}

.storage-available-label {
  display: block;
  width: 90%;
  margin-top: 20px;
  font-size: 17px;
  color: rgba(100, 100, 100, 1);
  font-weight: 800;
  padding-left: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #f6f6f6;
  outline: none;
}

.storage-information input[type="submit"] {
  margin-top: 25px;
  display: block;
  float: left;
  height: 35px;
  border: none;
  border-radius: 5px;
  width: 90%;
  font-size: 17px;
  font-weight: 700;
  color: #ffffff;
  background-color: #f26522;
  outline: none;
}

/* ErrorLabel.js */
.error-fill-all-labels {
  display: block;
  width: 90%;
  margin-top: 20px;
  font-size: 17px;
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
  font-weight: 800;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  outline: none;
}
