.overview-container {
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
}

.overview-container h2 {
  font-size: 17px;
  font-weight: 800;
  color: #646464;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  text-align: left;
  background-color: #eeeeee;
  margin: 0;
}

.overview-container h2 svg {
  font-size: 22px;
  vertical-align: -4px;
  padding-right: 10px;
}

.overview-body {
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  white-space: pre-line;
}

.overview-check-icon {
  font-size: 45px;
  margin: 10px auto 30px auto;
  color: #ffa500;
}

.overview-body h1 {
  font-size: 31px;
  padding-bottom: 10px;
  font-weight: 800;
  color: #646464;
}

.overview-body hr {
  margin-top: 45px;
  border: none;
  border-bottom: solid 1px #cfcfcf;
  width: 55%;
}

.overview-body img {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

button.overview-go-frontpage {
  outline: none;
  border: none;
  margin-top: 30px;
  background-color: #4caf50;
  color: #ffffff;
  padding: 8px 10px;
  border-radius: 7px;
  font-size: 16px;
  cursor: pointer;
  transition-duration: 0.4s;
}

button.overview-go-frontpage:hover {
  background-color: #3ba73d;
}

button.overview-go-frontpage svg {
  font-size: 16px;
  vertical-align: text-bottom;
  margin-left: 8px;
  transition-duration: 0.4s;
}

.overview-first-paragraph {
  padding: 10px 10px 0 10px 0;
  font-size: 20px;
  text-align: left;
  display: list-item;
  list-style-type: disc;
  margin-left: 35px;
}

.overview-second-paragraph {
  font-size: 20px;
  padding: 10px 10px 0 10px 0;
  text-align: left;
  display: list-item;
  list-style-type: disc;
  margin-left: 35px;
}
