.sign-body {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px 0px 30px 0px;
}

.sign-body h2 {
  font-size: 17px;
  font-weight: 800;
  color: #646464;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  text-align: left;
  background-color: #eeeeee;
  margin: 0;
}

.sign-body h2 svg {
  font-size: 22px;
  vertical-align: -4px;
  padding-right: 10px;
}

.sign-body ul {
  margin-top: 30px;
  list-style-type: none;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
}

.sign-body li {
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  margin-bottom: 2px;
  margin-top: 2px;
  position: relative;
}

.sign-body li:hover {
  border: 1px solid #d6e9c6;
  background-color: #dff0d8;
}

.sign-body li:active {
  background-color: #c9eeba;
}

.sign-body img {
  vertical-align: middle;
  height: 32px;
  padding: 5px;
}

.sign-body ul li p {
  display: inline-block;
  vertical-align: middle;
  font-size: 17px;
}

.sign-body li svg {
  position: absolute;
  font-size: 18px;
  color: #646464;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.sign-body .info-label {
  display: inline-block;
  padding: 10px 10px 10px 10px;
  margin-bottom: 0px;
  border: 1px solid #d6e9c6;
  border-radius: 3px;
  background-color: #dff0d8;
  font-style: normal;
  color: #646464;
  font-style: normal;
  font-size: 15px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
