/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/lato-v17-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/lato-v17-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/lato-v17-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/lato-v17-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/lato-v17-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/lato-v17-latin-regular.svg#Lato")
      format("svg"); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/lato-v17-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/lato-v17-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/lato-v17-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/lato-v17-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/lato-v17-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/lato-v17-latin-700.svg#Lato")
      format("svg"); /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/lato-v17-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/lato-v17-latin-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/lato-v17-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/lato-v17-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/lato-v17-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/lato-v17-latin-900.svg#Lato")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "BarlowCondensed";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/BarlowCondensed-Regular.ttf");
}

@font-face {
  font-family: "BarlowCondensed";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/BarlowCondensed-Medium.ttf");
}

@font-face {
  font-family: "BarlowCondensed";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/BarlowCondensed-SemiBold.ttf");
}

html,
body {
  margin: 0;
}

body {
  line-height: 1;
  background-color: #f6f6f6;
}

h2 {
  font-family: "BarlowCondensed";
  font-size: 18px !important;
}

.App {
  height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
