.select-storage-body {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 0px 0px 30px 0px;
  border-radius: 5px;
}

.select-storage-body h2 {
  width: 100%;
  font-size: 17px;
  font-weight: 800;
  color: #646464;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  text-align: left;
  background-color: #eeeeee;
  margin: 0;
}

.select-storage-body h2 svg {
  font-size: 22px;
  vertical-align: -4px;
  padding-right: 10px;
}

.flex-column {
  flex-basis: 45%;
  padding-left: 30px;
  margin-top: 10px;
}

@media screen and (max-width: 830px) {
  .flex-column {
    flex-basis: 100%;
    padding-right: 30px;
  }
}
