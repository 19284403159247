.agreement-body {
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: inset 0px 11px 8px -10px #d8d8d8,
    inset 0px -11px 8px -10px #d8d8d8;
}

.agreement-body h2 {
  box-shadow: 0px 4px 6px -2px #d8d8d8;
  font-size: 17px;
  font-weight: 800;
  color: #646464;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  text-align: left;
  background-color: #eeeeee;
  margin: 0;
}

.agreement-body h2 svg {
  font-size: 22px;
  vertical-align: -4px;
  padding-right: 10px;
}

.agreement-content {
  overflow: scroll;
  height: 57vh;
}
