.input-form {
  background-color: #ffffff;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 0px 40px 0px;
}

.input-form-body {
  padding-left: 40px;
  padding-right: 40px;
}

.input-form h2 {
  width: 100%;
  font-size: 17px;
  font-weight: 800;
  color: #646464;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  text-align: left;
  background-color: #eeeeee;
  margin: 0;
}

.input-form h2 svg {
  font-size: 22px;
  vertical-align: -4px;
  padding-right: 10px;
}

.input-form-header {
  width: 100%;
  margin-bottom: 20px;
}

.input-form-header .tab-bar {
  color: #646464;
  font-size: 17px;
  font-weight: 600;
  font-family: "BarlowCondensed";
}

.input-form select {
  display: block;
  width: calc(90% + 4px);
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 11px;
  padding-left: 6px;
  border: 1px solid #d3d3d3;
  outline: none;
  border-radius: 3px;
  margin-top: 10px;
}

.input-form select,
.input-form select option {
  color: #000000;
}

.input-form select:invalid,
.input-form select option[value=""] {
  color: #818181;
}

.input-form-multiple-choise .input-form-button-set-2 {
  padding-top: 15px;
  margin-left: 25px;
}

.input-form-multiple-choise {
  border: 1px solid #d3d3d3;
  width: calc(90% - 20px);
  margin-top: 10px;
  border-radius: 3px;
  padding: 10px;
}

.input-form-multiple-choise i,
p {
  color: #646464;
  font-style: normal;
  font-size: 15px;
}

.input-form-multiple-choise i:first-child {
  display: inline-block;
  padding: 0px 5px 10px 5px;
  margin-bottom: 15px;
  margin-left: 5px;
  border-bottom: 1px solid #d3d3d3;
  background-color: #ffffff;
  border-width: 0px 0px 1px 0px;
  border-radius: 0;
}

.input-form-multiple-choise i.tooltip-icon:nth-child(2),
i.tooltip-icon {
  display: inline-block;
  position: relative;
  padding: 0px 0px 0px 7px;
  margin-bottom: 0px;
  vertical-align: middle;
  margin: 0;
  border: none;
  font-size: 18px;
  color: #646464;
  font-style: normal;
}

.form-input-icon-container {
  position: relative;
  width: 100%;
  background-color: transparent;
}

.form-input-icon-absolute .tooltip-text {
  margin-left: -220px !important;
}

.form-input-icon-absolute .tooltip-text::after {
  left: 90%;
}

.form-input-icon-absolute {
  position: absolute;
  right: 12px;
  top: 19px;
}

i.tooltip-icon .tooltip-text {
  white-space: pre-line;
  visibility: hidden;
  width: 240px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px;
  position: absolute;
  bottom: 100%;
  margin-bottom: 15px;
  left: 50%;
  margin-left: -120px;
  background-color: #eeeeee;
  border: 1px solid #d3d3d3;
  z-index: 1;
}

.tooltip-text::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #eeeeee transparent transparent transparent;
}

i.tooltip-icon:hover .tooltip-text {
  color: #7e7d7d;
  visibility: visible;
}

.input-form-multiple-choise i {
  display: inline-block;
  padding: 10px 10px 10px 10px;
  margin-bottom: 0px;
  margin-left: 10px;
  border: 1px solid #d6e9c6;
  border-radius: 3px;
  background-color: #dff0d8;
}

.input-form-multiple-choise i.extra-price {
  display: inline-block !important;
  margin-top: 0 !important;
  border: 1px solid #ebccd1;
  background-color: #f2dede;

  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.input-form-multiple-choise p {
  border-bottom: 1px solid #d3d3d3;
  color: #646464;
  padding: 15px 5px 10px 5px;
  margin-left: 5px;
  margin-top: 0;
  display: table;
}

.input-form-multiple-choise button {
  outline: none;
  margin-left: 10px;
  border: none;
  padding: 10px;
  border-radius: 3px;
  width: 60px;
  border: 1px solid #d3d3d3;
  vertical-align: top;
}

.input-form-multiple-choise button:nth-child(3) {
  margin-left: 5px;
}

.input-form-multiple-choise button.active {
  background: #ffa500;
  color: #ffffff;
  font-weight: 700;
}

.input-form-multiple-choise .input-form-button-set-3 {
  margin-left: 50px;
}

.input-form-multiple-choise .input-form-button-set-3 button {
  display: inline-block;
}

.input-form textarea {
  display: block;
  width: 300px;
  float: left;
}

.input-form .btn-active {
  background-color: #ffa500;
  color: #ffffff;
  border: 1px solid #ffa500;
}

.input-form .info-label {
  display: inline-block;
  padding: 10px 10px 10px 10px;
  margin-bottom: 0px;
  border: 1px solid #d6e9c6;
  border-radius: 3px;
  background-color: #dff0d8;
  font-style: normal;
  color: #646464;
  font-size: 15px;
  margin-top: 5px;
  display: flex;
}

.input-form .info-label a {
  font-style: normal;
  color: #313030;
}

@media screen and (max-width: 860px) {
  .input-form-multiple-choise i:last-child {
    display: table;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .input-form-multiple-choise i:nth-child(4) {
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .input-form-body {
    padding-left: 10px;
    padding-right: 10px;
  }
}
