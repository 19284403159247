.modal-body {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 20px;
  z-index: 101;
}

.modal-container:before {
  position: fixed;
  top : 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  content:"";
  pointer-events: none;
  z-index: 100;
  opacity: 0.2
}