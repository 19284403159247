.tab-header-container {
  background-color: #ffffff;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.tab-header-body {
  display: flex;
  width: 850px;
}

.tab-container {
  margin-top: 35px;
}

.tab-header-body li {
  flex: 1;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.tab-header-body li hr {
  border: none;
  height: 10px;
  border-bottom: 3px solid #d3d3d3;
  flex-grow: 1;
}

.tab-header-body li hr.active {
  border-bottom: 3px solid #ffa500;
}

.tab-header-body li button {
  font-size: 14px;
  font-weight: 800;
  border: none;
  outline: none;
  background-color: transparent;
  flex: 1;
}

.tab-header-body li button i {
  background-color: #f6f6f6;
  color: black;
  font-style: normal;
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-header-body li button i.active {
  color: #ffffff;
  background-color: #ffa500;
}

.tab-header-body li button p {
  margin-bottom: 0;
  white-space: nowrap;
  font-size: 12px;
  color: #646464;
}

.tab-container {
  width: 100%;
}

.tab-container .tab-btn {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 75px;
  color: #ffffff;
  background: #f17940;
  border: 1px solid #f17940;
  font-family: "BarlowCondensed";
  font-weight: 500;
}

.tab-container .tab-btn:hover {
  background: #f16b2c;
}

.tab-container .next-btn svg {
  font-size: 15px;
}

.tab-container .previous-btn svg {
  font-size: 15px;
  padding-left: 8px;
}

.next-btn {
  float: right;
}

.previous-btn {
  float: left;
}

.tab-container button:disabled.tab-btn {
  background-color: #f6f6f6;
  color: #646464;
  border: 1px solid #c4c4c4;
}

.tab-btn-icon-container {
  display: inline;
  position: relative;
  animation-name: bounce;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes bounce {
  0% {
    left: 0px;
  }
  50% {
    left: 6px;
  }
  100% {
    left: 0px;
  }
}

@media screen and (max-width: 992px) {
  .tab-header-body {
    width: 100%;
  }
}
